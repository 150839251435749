<template>
    <router-view />
</template>

<script>
export default {
  name: 'app',
};
</script>

<style lang="scss">
html,body{
  padding: 0;
  margin: 0;
}
</style>
